<template>
	<div>
		<searchCard>
			<div class="flex">
				<b-form-radio
					v-for="(v, i) in radioOpts"
					class="top-1.5"
					v-model="opt.userType"
					name="searchRadio"
					:value="v.value"
				>
					<span class="mr-6" v-text="v.label" />
				</b-form-radio>

				<b-form-input
					class="w-1/4 ml-12"
					v-model.trim="opt.title"
					name="keyword"
					placeholder="제목 검색"
					@keyup.enter="search"
				/>
				<b-button class="px-10 ml-2" @click="search">검색</b-button>
			</div>
		</searchCard>

		<b-card>
			<b-button class="px-10 mb-2 float-right z-10" @click="addNotice">등록</b-button>
			<tb :inf="inf" :res="res" />
		</b-card>
		<noticePopup :pr="ths" cName="noticePopup" />
	</div>
</template>

<script>
import noticePopup from 'pages/noticePopup'

export default {
	components: { noticePopup },
	data() {
		const radioOpts = [
				{
					label: '전체',
					value: '',
				},
				{
					label: '모든회원',
					value: 'T',
				},
				{
					label: '핫티스트',
					value: 'A',
				},
				{
					label: '일반회원',
					value: 'L',
				},
			],
			defOpt = {
				userType: radioOpts[0].value,
				title: '',
			}
		return {
			radioOpts,
			opt: { ...defOpt },
			defOpt,
			lastOpt: {},
			res: {},
			inf: [
				{ title: 'No.', model: 'idx', size: 2 },
				{ title: 'Seq', key: 'noticeSeq', size: 2 },
				{
					title: '구분',
					key: 'userType',
					size: 4,
				},
				{ title: '제목', key: 'title', size: 16, notCenter: 1 },
				{ title: '수정일', key: 'updateDt', size: 6 },
				{ title: '작성일', key: 'createDt', size: 6 },
				{
					title: '수정',
					model: 'button',
					icon: 'pencil-square',
					size: 2,
					func: item => {
						this.noticePopup.open(item.noticeSeq)
					},
				},
			],
		}
	},
	methods: {
		search() {
			let data = {
				orderBy: getOrder('createDt'),
				paging: getPaging(),
			}
			const opt = this.opt

			if (opt.userType) data.userType = [opt.userType]
			if (opt.title) data.title = opt.title

			this.lastOpt = data
			this.changePage()
		},
		changePage(page = 1) {
			this.lastOpt.paging.pageNo = page - 1
			return postApi('/noticeSelect/noticeList', this.lastOpt).then(res => {
				res.list = res.list.map(v => {
					;['createDt', 'updateDt'].map(k => (v[k] = v[k] ? v[k].replace('T', ' ') : ''))
					v.userType = getOptsFromValue(this.radioOpts, v.userType).label
					return v
				})
				this.res = res
				return res
			})
		},
		addNotice() {
			this.noticePopup.open()
		},
	},
	created() {
		this.search()
	},
}
</script>
