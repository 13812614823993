<template>
	<basePopup :title="isEdit ? '공지사항 수정' : '공지사항 등록'">
		<h6>구분</h6>
		<div class="flex mb-6">
			<b-form-radio
				v-for="(v, i) in radioOpts"
				class="top-1.5"
				v-model="input.userType"
				name="popupSearchRadio"
				:value="v.value"
			>
				<span class="mr-6" v-text="v.label" />
			</b-form-radio>
		</div>
		<b-form-input class="mb-4" v-model.trim="input.title" placeholder="제목" @keyup.enter="save" />
		<quillEditor v-show="!isHtml" :pr="ths" cName="quill" fileType="notice" />
		<b-form-textarea
			v-show="isHtml"
			class="h-96 resize-none mb-10"
			v-model="htmlCont"
			:placeholder="'내용을 입력해주세요'"
		/>

		<template #footer>
			<b-button
				class="px-4 absolute left-4"
				@click="setHtml"
				size="sm"
				variant="info"
				v-text="!isHtml ? 'HTML 열기' : 'HTML 적용'"
			/>
			<b-button class="mr-4 px-10" @click="save" size="sm" variant="primary" v-text="'저장'" />
			<b-button v-if="isEdit" class="mr-4 px-10" @click="remove" size="sm" variant="info" v-text="'삭제'" />
			<b-button class="mr-4 px-10" @click="isShow = false" size="sm" variant="gray" v-text="'취소'" />
		</template>
	</basePopup>
</template>

<script>
import quillEditor from 'comp/local/quillEditor'
import basePopup from 'comp/local/basePopup'
export default {
	//props: ['roleGroupKeyOpts', 'statusKeyOpts'],
	components: {
		quillEditor,
		basePopup,
	},
	data() {
		const radioOpts = [
			{
				label: '모든회원',
				value: 'T',
			},
			{
				label: '핫티스트',
				value: 'A',
			},
			{
				label: '일반회원',
				value: 'L',
			},
		]
		return {
			isShow: false,
			isEdit: false,
			isHtml: false,
			htmlCont: '',
			noticeSeq: -1,
			radioOpts,
			input: {
				userType: radioOpts[0].value,
				title: '',
			},
		}
	},
	methods: {
		open(seq) {
			if (seq) {
				this.isEdit = true
				this.noticeSeq = seq
				postApi('/noticeSelect/noticeDetail', { noticeSeq: seq }).then(res => {
					this.input.title = res.title
					this.input.userType = res.userType
					this.quill.cont = res.content
				})
			} else {
				this.isEdit = false
				this.input.title = ''
				this.input.userType = this.radioOpts[0].value
			}
			this.isHtml = false
			this.isShow = true
		},
		setHtml() {
			this.isHtml = !this.isHtml
			if (this.isHtml) this.htmlCont = this.quill.cont
			else {
				this.quill.cont = this.htmlCont
				this.htmlCont = ''
			}
		},
		save(isDelete = false) {
			const input = this.input,
				cont = this.quill.cont
			let error = []

			if (!input.title) error.push('제목을 입력해주세요')
			if (!cont) error.push('내용을 입력해주세요')

			if (error.length) alert.w(error)

			let data = {
				adminName: layout.user.username,
				userType: input.userType,
				title: input.title,
				adminSeq: layout.user.seqNo,
				content: cont,
			}

			let promise
			const successMsg = this.isEdit ? '수정' : '등록'
			if (!this.isEdit) promise = postApi('/noticeHandle/insertNotice', data)
			else {
				data = {
					...data,
					saveStatus: isDelete === true ? 'N' : 'Y',
					updateAdminSeq: layout.user.seqNo,
					noticeSeq: this.noticeSeq,
				}
				promise = putApi('/noticeHandle/updateNotice', data)
			}
			promise.then(() => {
				this.afterFunc(successMsg)
			})
		},
		remove() {
			putApi('/noticeHandle/deleteNotice', {
				noticeSeq: this.noticeSeq,
				updateAdminSeq: layout.user.seqNo,
			}).then(res => {
				this.afterFunc('삭제')
			})
		},
		afterFunc(text) {
			alert.s(text + '되었습니다.')
			this.isShow = false
			this.p.changePage(this.$parent.lastOpt.paging.pageNo * 1 + 1)
		},
	},
}
</script>
