<template>
	<quill-editor class="bg-white h-96 mb-20" v-model="cont" :options="options" />
	<!--
	:content="content"
	v-model="content"

    :options="editorOption"
    @blur="onEditorBlur($event)"
    @focus="onEditorFocus($event)"
    @ready="onEditorReady($event)"
    @change="onEditorChange($event)"
	-->
</template>

<script>
import { uploadFile } from 'libs/axios'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { Quill, quillEditor } from 'vue-quill-editor'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'

const Link = Quill.import('formats/link')

class MyLink extends Link {
	static create(value) {
		const node = super.create(value)
		value = this.sanitize(value)
		node.setAttribute('href', value)
		node.setAttribute('target', '_self')
		return node
	}
}

Quill.register(MyLink)
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

export default {
	components: {
		quillEditor,
	},

	props: { fileType: { default: 'none' }, cName: { default: 'quill' } },
	data() {
		const ths = this
		return {
			cont: '',
			options: {
				placeholder: '내용을 입력해주세요',
				//https://quilljs.com/docs/modules/toolbar/
				modules: {
					//다른 형식의 파일은 라이브러리에서 이미 막힌다. 이미지만 걸러주기 때문에 유효성 검사 필요 없을듯
					imageDropAndPaste: {
						handler: (srcUrl, mime, imgData) => this.imgHandler(imgData.toFile(imgData.name)),
					},
					toolbar: {
						container: [
							['bold', 'link', 'image'],
							[{ list: 'ordered' }, { list: 'bullet' }],
							[{ indent: '-1' }, { indent: '+1' }],
							[{ size: ['small', false, 'large', 'huge'] }],
							[{ header: [1, 2, 3, 4, 5, 6, false] }],
							[{ color: [] }, { background: [] }],
							[{ align: [] }],
						],
						handlers: {
							image() {
								const input = document.createElement('input')
								input.setAttribute('type', 'file')
								input.setAttribute('accept', 'image/*')
								input.onchange = () => ths.imgHandler(input.files[0])
								input.click()
							},
						},
					},
				},
			},
		}
	},

	methods: {
		imgHandler(file) {
			if (file.size >= 3145728) alert.w('3MB 이하 이미지만 업로드 가능합니다.')
			else {
				uploadFile(file, this.fileType).then(
					url =>
						//?? 이건 왜 s3 폴더명이 붙어서 바로 사용 못하도록 내려오냐..
						(this.cont += `<img src='${s3Url}${/\/.*/.exec(url)[0]}' />`)
				)
			}
		},
	},
}
</script>
